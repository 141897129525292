import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AffiliateLayout from "./common/AffiliateLayout";
import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { dashboarddataApi, getreferralUsers, PayoutReportDataApi } from "backendServices/ApiCalls";
import CustomerTable from "./common/CustomerTable";
import { Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useNavigate } from "react-router-dom";
import RefCustomerTable from "./common/RefCustomerTable";
import PaymentTable from "./common/PaymentTable";


export default function MemberDashboard() {
  const { t } = useTranslation();
  const [dashboardData, setdDashboardData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [affLoading, setaffLoading] = useState(false);
  const { setAffData, refUsers } =
    useContext(CustomProvider);
  const [affiliateData, setAffiliateData] = useState({});
  const [currentTab, setCurrentTab] = useState("trial")

  const navigate = useNavigate();
  const [timeLeftOneMonth, setTimeLeftOneMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeLeftThreeMonth, setTimeLeftThreeMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeLeftSixMonth, setTimeLeftSixMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [payoutReportData, setPayoutReportData] = useState([]);

  const now = new Date();
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const formattedLastDay = lastDayOfMonth.toISOString().slice(0, 10) + 'T23:59:59';

  const calculateTimeLeft = (month) => {
    const difference = +new Date(month) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setTimeLeftOneMonth(calculateTimeLeft(formattedLastDay));
    }, 1000);

    return () => clearTimeout(timer1);
  }, []); // Empty dependency array to run the effect only once

  useEffect(() => {
    const timer2 = setTimeout(() => {
      setTimeLeftThreeMonth(calculateTimeLeft(formattedLastDay));
    }, 1000);

    return () => clearTimeout(timer2);
  }, []);

  useEffect(() => {
    const timer3 = setTimeout(() => {
      setTimeLeftSixMonth(calculateTimeLeft(formattedLastDay));
    }, 1000);

    return () => clearTimeout(timer3);
  }, []);

  const GetDashboardData = () => {
    setLoader(true);
    dashboarddataApi(
      (response) => {
        setdDashboardData(response?.data?.dashboardData);
        setAffData(response?.data?.dashboardData)
        setLoader(false);
      },
      (error) => {
        setLoader(false);
        console.log(error?.response?.data);
      }
    );
  };


  // if (loader || loading ) {
  //   return (
  //     <Div
  //       sx={{
  //         display: "flex",
  //         minWidth: 0,
  //         alignItems: "center",
  //         alignContent: "center",
  //         height: "100%",
  //       }}
  //     >
  //       <CircularProgress sx={{ m: "-40px auto 0" }} />
  //     </Div>
  //   );
  // }

  const returnTimer = (timepara) => {
    const numberCss = {
      fontFamily: "'Digital-7'",
      lineHeight: "33.57px",
      fontSize: "32px",
      fontWeight: "400",
      background: "#ffffff69",
      padding: "0px 2px"
    }

    let days = "00";
    let hours = "00";
    let minutes = "00";
    let seconds = "00";

    if (Object.keys(timepara).length > 0) {
      days = timepara.days.toString().padStart(2, '0')
      hours = timepara.hours.toString().padStart(2, '0')
      minutes = timepara.minutes.toString().padStart(2, '0')
      seconds = timepara.seconds.toString().padStart(2, '0')
    }


    if (timepara.days > 0) {
      return (
        <>
          <div>
            <div>
              <span style={numberCss}>{days[0]}</span>
              <span style={{ ...numberCss, marginLeft: "4px" }}>{days[1]}</span>
            </div>
            <div
              style={{
                lineHeight: "18.23px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Days
            </div>
          </div>
          <div>
            <div>
              <span style={numberCss}>{hours[0]}</span>
              <span style={{ ...numberCss, marginLeft: "4px" }}>{hours[1]}</span>
            </div>
            <div
              style={{
                lineHeight: "18.23px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Hours
            </div>
          </div>
          <div>
            <div>
              <span style={numberCss}>{minutes[0]}</span>
              <span style={{ ...numberCss, marginLeft: "4px" }}>{minutes[1]}</span>
            </div>
            <div
              style={{
                lineHeight: "18.23px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Minutes
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <div>
              <span style={numberCss}>{hours[0]}</span>
              <span style={{ ...numberCss, marginLeft: "4px" }}>{hours[1]}</span>
            </div>
            <div
              style={{
                lineHeight: "18.23px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Hours
            </div>
          </div>
          <div>
            <div>
              <span style={numberCss}>{minutes[0]}</span>
              <span style={{ ...numberCss, marginLeft: "4px" }}>{minutes[1]}</span>
            </div>
            <div
              style={{
                lineHeight: "18.23px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Minutes
            </div>
          </div>
          <div>
            <div>
              <span style={numberCss}>{seconds[0]}</span>
              <span style={{ ...numberCss, marginLeft: "4px" }}>{seconds[1]}</span>
            </div>
            <div
              style={{
                lineHeight: "18.23px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Seconds
            </div>
          </div>
        </>
      );
    }
  };



  // const activeSale = 49;
  const activeSale = dashboardData?.personalActiveSale?.activeSale ?? 0;
  const widthPercentage = ((activeSale > 50 ? 50 : activeSale) / 50) * 100;

  // Define styles
  const styles = [
    { left: `calc(${widthPercentage}% - 20px)` },
    { left: `calc(${widthPercentage}% - 5px)` },
    { left: `calc(${widthPercentage}% + 10px)` },
    { left: `calc(${widthPercentage}% + 25px)` },
    { left: `calc(${widthPercentage}% + 40px)` },
    { left: `calc(${widthPercentage}% + 60px)` },
    { left: `calc(${widthPercentage}% + 80px)` },
    { left: `calc(${widthPercentage}% + 90px)` },
    { left: `calc(${widthPercentage}% + 100px)` },
    { left: `calc(${widthPercentage}% + 115px)` },
    { left: `calc(${widthPercentage}% + 125px)` },
    { left: `calc(${widthPercentage}% + 135px)` },
    { left: `calc(${widthPercentage}% + 145px)` },
    { left: `calc(${widthPercentage}% + 155px)` },
    { left: `calc(${widthPercentage}% + 165px)` },
    { left: `calc(${widthPercentage}% + 175px)` },
    { left: `calc(${widthPercentage}% + 185px)` },
    { left: `calc(${widthPercentage}% + 190px)` },
    { left: `calc(${widthPercentage}% + 190px)` },
    { left: `calc(${widthPercentage}% + 195px)` },
    { left: `calc(${widthPercentage}% + 205px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 215px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 215px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 210px)` },
    { left: `calc(${widthPercentage}% + 205px)` },
    { left: `calc(${widthPercentage}% + 205px)` },
    { left: `calc(${widthPercentage}% + 195px)` },
    { left: `calc(${widthPercentage}% + 195px)` },
    { left: `calc(${widthPercentage}% + 185px)` },
    { left: `calc(${widthPercentage}% + 175px)` },
    { left: `calc(${widthPercentage}% + 165px)` },
    { left: `calc(${widthPercentage}% + 155px)` },
    { left: `calc(${widthPercentage}% + 145px)` },
    { left: `calc(${widthPercentage}% + 135px)` },
    { left: `calc(${widthPercentage}% + 125px)` },
    { left: `calc(${widthPercentage}% + 115px)` },
    { left: `calc(${widthPercentage}% + 105px)` },
    { left: `calc(${widthPercentage}% + 95px)` },
    { left: `calc(${widthPercentage}% + 85px)` },
    { left: `calc(${widthPercentage}% + 65px)` },
    { left: `calc(${widthPercentage}% + 45px)` },
    { left: `calc(${widthPercentage}% + 25px)` },
    { left: `calc(${widthPercentage}% + 05px)` },
    { left: `calc(${widthPercentage}% - 05px)` },
    { left: `calc(${widthPercentage}% - 20px)` },
  ];

  // Get the style based on the activeSale value
  const getStyle = () => {
    // Ensure activeSale is within the valid range
    const index = Math.min(activeSale, styles.length - 1);
    return styles[index] || styles[0]; // Return default style if index is out of range
  };

  const appliedStyle = getStyle();
  const totalUsers = dashboardData?.nextPayoutPer?.numberOfUsers || 0;
  // const activeSale1 = dashboardData?.personalActiveSale?.activeSale || 0;
  const progressValue = (activeSale / totalUsers) * 100;

  const commonButtonStyle = {
    width: "150px",
    height: "40px",
    borderRadius: "12px 12px 0px 0px",
    color: "rgba(21, 29, 72, 1)",
    backgroundColor: "rgb(255, 255, 255)",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",

  };

  const activeStyle = {
    backgroundColor: "rgb(44, 115, 255)",
    color: "#fff",
  };

  const hoverStyle = {
    backgroundColor: "#e6e6e6",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  const InvestmentData = () => {
    PayoutReportDataApi(
      (response) => {
        setPayoutReportData(response?.data?.data);
        // setObj(response?.data?.obj);
      },
      (error) => {
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    GetDashboardData();
    InvestmentData();
  }, []);

  return (
    <AffiliateLayout>
      {
        loader
          ? <div style={{ position: 'absolute', height: "100vh", width: "100%" }}>
            <CircularProgress sx={{
              position: 'absolute',
              top: '50%',
              left: '40%',
              transform: 'translate(-50%, -50%)',
            }} /></div>
          : <div>
            <div
              className="affiliate-dashboard-topbar"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1.4fr",
                gap: "16px",
              }}
            >
              <div
                className="bg-red"
                style={{
                  border: "1px dashed rgba(243, 121, 126, 1)",
                  borderRadius: "12px",
                  padding: "20px",
                  background: "rgba(243, 121, 126, 0.12)",
                  lineHeight: "32px",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "'DM Sans'",
                }}
              >
                <div className="affiliate-dashboard-topbar-title">
                  {t("pages.title.lifetime-earning")}
                </div>
                <div
                  className="affiliate-dashboard-topbar-value"
                  style={{
                    fontSize: "30px",
                    lineHeight: "32px",
                    fontWeight: "600",
                    fontFamily: "'DM Sans'",
                    color: "rgba(243, 121, 126, 1)",
                    marginTop: "5px",
                    marginBottom: "0px",
                  }}
                >
                  {dashboardData?.cSymbol}{" "}
                  {(dashboardData?.lifeTImeEarning || 0).toFixed(2)}
                </div>
              </div>
              <div
                className="bg-blue"
                style={{
                  border: "1px dashed rgba(125, 160, 250, 1)",
                  borderRadius: "12px",
                  padding: "20px",
                  background: "rgba(125, 160, 250, 0.12)",
                  lineHeight: "32px",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "'DM Sans'",
                }}
              >
                <div className="affiliate-dashboard-topbar-title">
                  {t("pages.title.last-month-earning")} ({dashboardData?.cSymbol})
                </div>
                <div
                  className="affiliate-dashboard-topbar-value"
                  style={{
                    fontSize: "30px",
                    lineHeight: "32px",
                    fontWeight: "600",
                    fontFamily: "'DM Sans'",
                    color: "rgba(125, 160, 250, 1)",
                    marginTop: "5px",
                    marginBottom: "0px",
                  }}
                >
                  {dashboardData?.cSymbol}{" "}
                  {(dashboardData?.lastMonthEarning || 0).toFixed(2)}
                </div>
              </div>
              <div
                className="bg-green"
                style={{
                  border: "1px dashed rgba(34, 154, 22, 1)",
                  borderRadius: "12px",
                  padding: "20px",
                  background: "rgba(34, 154, 22, 0.12)",
                  lineHeight: "32px",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "'DM Sans'",
                }}
              >
                <div className="affiliate-dashboard-topbar-title">
                  {t("pages.title.total-payment")}
                </div>
                <div
                  className="affiliate-dashboard-topbar-value"
                  style={{
                    fontSize: "30px",
                    lineHeight: "32px",
                    fontWeight: "700",
                    fontFamily: "'DM Sans'",
                    color: "rgba(34, 154, 22, 1)",
                    marginTop: "5px",
                    marginBottom: "0px",
                  }}
                >
                  {dashboardData?.cSymbol}{" "}
                  {(dashboardData?.totalPayment || 0).toFixed(2)}
                </div>
              </div>
              <div
                className="bg-blue"
                style={{
                  border: "1px dashed rgba(125, 160, 250, 1)",
                  borderRadius: "12px",
                  padding: "15px 15px 0px",
                  background: "rgba(125, 160, 250, 0.2)",
                  lineHeight: "32px",
                  fontSize: "16px",
                  fontWeight: "500",
                  fontFamily: "'DM Sans'",
                  backgroundImage:
                    "url(/images/affiliate-dashboard-button-bg.svg)",
                  backgroundPosition: "bottom right",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  textAlign: "center",
                  paddingBottom: 0,
                }}
              >
                <div className="affiliate-dashboard-topbar-title">
                  {t("pages.title.end-of-the-month-in")}: {" "}
                </div>
                <div
                  className="affiliate-dashboard-topbar-value"
                  style={{
                    fontSize: "30px",
                    lineHeight: "16px",
                    fontWeight: "700",
                    fontFamily: "'DM Sans'",
                    color: "rgba(94, 59, 183, 1)",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "15px",
                    }}
                  >
                    {returnTimer(timeLeftOneMonth)}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                gap: "20px",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  padding: "20px",
                  background: "#fff",
                  borderRadius: "12px",
                  boxShadow: "rgba(145, 158, 171, 0.15) 0px 7px 20px",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    lineHeight: "32px",
                    fontWeight: "600",
                    color: "rgb(23, 15, 73)",
                  }}
                >
                  {t("pages.title.my-earning")}
                </div>
                <div>
                  <div
                    style={{
                      border: "1px solid rgba(145, 158, 171, 0.24)",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      color: "rgba(33, 43, 54, 0.8)",
                      fontSize: "14px",
                      lineHeight: "24px",
                      fontWeight: "500",
                      margin: "10px 0px",
                      background: "rgba(249, 250, 251, 1)",
                    }}
                  >
                    <span>{t("pages.title.level")}{" "} 01</span>
                    <span>
                      {dashboardData?.cSymbol}{" "}
                      {(dashboardData?.totalEarning?.l1 || 0.0).toFixed(2)}
                    </span>
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(145, 158, 171, 0.24)",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      color: "rgba(33, 43, 54, 0.8)",
                      fontSize: "14px",
                      lineHeight: "24px",
                      fontWeight: "500",
                      margin: "10px 0px",
                      background: "rgba(249, 250, 251, 1)",
                    }}
                  >
                    <span>{t("pages.title.level")}{" "} 02</span>
                    <span>
                      {dashboardData?.cSymbol}{" "}
                      {(dashboardData?.totalEarning?.l2 || 0.0).toFixed(2)}
                    </span>
                  </div>
                  {/* <div
                style={{
                  border: "1px solid rgba(145, 158, 171, 0.24)",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 15px",
                  color: "rgba(33, 43, 54, 0.8)",
                  fontSize: "14px",
                  lineHeight: "24px",
                  fontWeight: "500",
                  margin: "10px 0px",
                  background: "rgba(249, 250, 251, 1)",
                }}
              >
                <span>Bonus</span>
                <span>
                  {dashboardData?.cSymbol}{" "}
                  {(dashboardData?.totalEarning?.bonus || 0).toFixed(2)}
                </span>
              </div> */}
                  <div
                    style={{
                      border: "1px solid rgba(145, 158, 171, 0.24)",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      color: "rgba(33, 43, 54, 0.8)",
                      fontSize: "14px",
                      lineHeight: "24px",
                      fontWeight: "500",
                      margin: "10px 0px",
                      background: "rgba(249, 250, 251, 1)",
                    }}
                  >
                    <span>{t("pages.title.Others")}</span>
                    <span>
                      {dashboardData?.cSymbol}{" "}
                      {(dashboardData?.totalEarning?.others || 0).toFixed(2)}
                    </span>
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(145, 158, 171, 0.24)",
                      borderRadius: "12px",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 15px",
                      color: "rgba(33, 43, 54, 0.8)",
                      fontSize: "14px",
                      lineHeight: "24px",
                      fontWeight: "500",
                      margin: "10px 0px",
                      background: "rgba(233, 252, 212, 1)",
                    }}
                  >
                    <span>{t("pages.title.total-earning")}</span>
                    <span style={{ color: "rgba(34, 154, 22, 1)" }}>
                      {dashboardData?.cSymbol}{" "}
                      {(
                        (dashboardData?.totalEarning?.l1 || 0.0) +
                        (dashboardData?.totalEarning?.l2 || 0.0) +
                        (dashboardData?.totalEarning?.others || 0.0)
                      ).toFixed(2)}
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: "20px",
                  background: "#fff",
                  borderRadius: "12px",
                  boxShadow: "rgba(145, 158, 171, 0.15) 0px 7px 20pxs",
                }}
              >
                <div>
                  <h2
                    style={{
                      fontFamily: "'DM Sans'",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "32px",
                      color: "rgba(23, 15, 73, 1)",
                    }}
                  >
                    {t("pages.title.MySalesBonus")}
                  </h2>
                  <p
                    style={{
                      fontFamily: "'DM Sans'",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "rgba(33, 43, 54, 0.8)",
                      marginTop: "5px",
                    }}
                  >
                    {t("pages.title.personalactivesalesdetail")}
                  </p>
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    display: "grid",
                    gridTemplateColumns: "1fr 1.35fr 1fr",
                    gap: "20px",
                  }}
                >
                  <div>
                    <div
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "32px",
                        textAlign: "center",
                        color: "rgb(23, 15, 73)",
                        marginBottom: "15px",
                      }}
                    >
                      {t("pages.title.my-current-payout")}
                    </div>
                    <div>
                      <div
                        style={{
                          background: "rgba(44, 115, 255, 0.05)",
                          fontFamily: "'DM Sans'",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "32px",
                          textAlign: "center",
                          marginBottom: "15px",
                          borderRadius: "12px",
                          border: "1px solid rgba(44, 115, 255, 0.15)",
                          padding: "8px",
                          textTransform: "uppercase"
                        }}
                      >
                        <div>{t("pages.title.Niveau1")}</div>
                        <div
                          style={{
                            fontFamily: "'DM Sans'",
                            fontSize: "21px",
                            fontWeight: "600",
                            lineHeight: "28px",
                            textAlign: "center",
                            color: "rgba(44, 115, 255, 1)",
                          }}
                        >
                          {
                            totalUsers === 1
                              ? 20
                              : dashboardData?.currentPayoutPer?.l1}%
                        </div>
                      </div>
                      <div
                        style={{
                          background: "rgba(44, 115, 255, 0.05)",
                          fontFamily: "'DM Sans'",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "32px",
                          textAlign: "center",
                          marginBottom: "15px",
                          borderRadius: "12px",
                          border: "1px solid rgba(44, 115, 255, 0.15)",
                          padding: "8px",
                          textTransform: "uppercase"
                        }}
                      >
                        <div>{t("pages.title.Niveau2")}</div>
                        <div
                          style={{
                            fontFamily: "'DM Sans'",
                            fontSize: "21px",
                            fontWeight: "600",
                            lineHeight: "28px",
                            textAlign: "center",
                            color: "rgba(44, 115, 255, 1)",
                          }}
                        >
                          {totalUsers === 1
                            ? 0
                            : dashboardData?.currentPayoutPer?.l2}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "32px",
                        textAlign: "center",
                        color: "rgba(23, 15, 73, 1)",
                        marginBottom: "15px",
                      }}
                    >
                      {t("pages.title.sales-needed-till-next-payout")}
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        sx={{
                          color: "rgba(44, 115, 255, 1)",
                          position: "absolute",
                          zIndex: 1,
                        }}
                        size={136}
                        variant="determinate"
                        value={progressValue > 100 ? 100 : progressValue}
                      />
                      <CircularProgress
                        sx={{ color: "rgba(44, 115, 255, 0.09)" }}
                        size={136}
                        variant="determinate"
                        value={100}
                      />
                      <label
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "32px",
                          fontWeight: "600",
                          lineHeight: "55px",
                          textAlign: "center",
                          position: "absolute",
                          color: "rgba(44, 115, 255, 1)",
                        }}
                      >
                        {activeSale}/{totalUsers}
                      </label>
                    </Box>
                  </div>
                  <div>
                    <div
                      style={{
                        fontFamily: "DM Sans",
                        fontSize: "18px",
                        fontWeight: "600",
                        lineHeight: "32px",
                        textAlign: "center",
                        color: "rgba(23, 15, 73, 1)",
                        marginBottom: "15px",
                      }}
                    >
                      {t("pages.title.next-payout")}
                    </div>
                    <div>
                      <div
                        style={{
                          background: "rgba(44, 115, 255, 0.05)",
                          fontFamily: "'DM Sans'",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "32px",
                          textAlign: "center",
                          marginBottom: "15px",
                          borderRadius: "12px",
                          border: "1px solid rgba(44, 115, 255, 0.15)",
                          padding: "8px",
                          textTransform: "uppercase"
                        }}
                      >
                        <div>{t("pages.title.Niveau1")}</div>
                        <div
                          style={{
                            fontFamily: "'DM Sans'",
                            fontSize: "21px",
                            fontWeight: "600",
                            lineHeight: "28px",
                            textAlign: "center",
                            color: "rgba(44, 115, 255, 1)",
                          }}
                        >
                          {
                            totalUsers === 1
                              ? 20
                              : dashboardData?.nextPayoutPer?.l1}%
                        </div>
                      </div>
                      <div
                        style={{
                          background: "rgba(44, 115, 255, 0.05)",
                          fontFamily: "'DM Sans'",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "32px",
                          textAlign: "center",
                          marginBottom: "15px",
                          borderRadius: "12px",
                          border: "1px solid rgba(44, 115, 255, 0.15)",
                          padding: "8px",
                          textTransform: "uppercase"
                        }}
                      >
                        <div>{t("pages.title.Niveau2")}</div>
                        <div
                          style={{
                            fontFamily: "'DM Sans'",
                            fontSize: "21px",
                            fontWeight: "600",
                            lineHeight: "28px",
                            textAlign: "center",
                            color: "rgba(44, 115, 255, 1)",
                          }}
                        >
                          {
                            totalUsers === 1
                              ? 0
                              : dashboardData?.nextPayoutPer?.l2}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "18px",
                      fontWeight: "600",
                      lineHeight: "21px",
                      textAlign: "left",
                      color: "rgba(23, 15, 73, 1)",
                      marginBottom: "10px",
                      marginTop: "5px",
                    }}
                  >
                    {t("pages.title.my-personal-active-sale")}
                  </div>
                  <div>
                    <div
                      className="as-prog"
                      style={{
                        height: "8px",
                        marginBottom: "30px",
                        background: "rgba(44, 115, 255, 0.2)",
                        borderRadius: "2px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: `${widthPercentage}%`,
                          height: "8px",
                          background: "rgba(44, 115, 255, 1)",
                          borderRadius: "2px",
                          position: "relative",
                        }}
                      >
                        <span className='active-sale-line'
                          style={{
                            position: "absolute",
                            // left: appliedStyle.left,
                            right: 'calc(0% - 16px)',
                            bottom: "-38px",
                            fontFamily: "'DM Sans'",
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "16.45px",
                            textAlign: "center",
                            color: "rgba(44, 115, 255, 1)",
                            border: "1px solid rgba(44, 115, 255, 0.5)",
                            background: "rgba(44, 115, 255, 0.1)",
                            padding: "6px",
                            borderRadius: "50%",
                            height: "32px",
                            width: "32px",
                          }}
                        >
                          {activeSale}
                        </span>
                        <span>&nbsp;</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="grid-box"
              style={{
                backgroundColor: '#fff',
                boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)',
                borderRadius: '12px',
                margin: '20px 0',
              }}
            >
              <div className="actual-payout" style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                borderRadius: '12px',
                background: "rgba(44, 115, 255, 0.1)",
                boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)',
                padding: '20px',
                alignItems: "center"
              }}>
                <h3
                  style={{
                    color: "rgba(27, 100, 243, 1)",
                    fontSize: "24px",
                  }}
                >
                  {t("pages.title.YourActualPayout%isBasedontheFollowing")}:
                </h3>
                <div
                  className="NVL-table1"
                  style={{
                    backgroundColor: '#fff',
                    boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    margin: '20px 0',
                    padding: '20px',
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("pages.title.DirectActives")}</TableCell>
                        <TableCell>1</TableCell>
                        <TableCell>3</TableCell>
                        <TableCell>10</TableCell>
                        <TableCell>20</TableCell>
                        <TableCell>30</TableCell>
                        <TableCell>50</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      <TableRow>
                        <TableCell>{t("pages.title.Niveau1")}</TableCell>
                        <TableCell>20%</TableCell>
                        <TableCell>20%</TableCell>
                        <TableCell>22.5%</TableCell>
                        <TableCell>25%</TableCell>
                        <TableCell>30%</TableCell>
                        <TableCell>30%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>{t("pages.title.Niveau2")}</TableCell>
                        <TableCell>0%</TableCell>
                        <TableCell>20%</TableCell>
                        <TableCell>22.5%</TableCell>
                        <TableCell>25%</TableCell>
                        <TableCell>25%</TableCell>
                        <TableCell>30%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            {/* <div
              className="grid-box"
              style={{
                backgroundColor: '#fff',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 7px 20px',
                borderRadius: '12px',
                margin: '30px 0',
                padding: "0",
              }}
            >
              <div className="actual-payout"  >
                <h3
                  style={{
                    color: "rgb(23, 15, 73)",
                    fontSize: "18px",
                    margin: "0",
                    padding: "10px 24px",
                    background: "rgb(224, 228, 232)",
                    borderRadius: "12px 12px 0px 0px",
                  }}
                >
                  {t("pages.title.YourActualPayout%isBasedontheFollowing")}:
                </h3>
                <div className="NVL-table1" >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{color: 'rgb(27, 100, 243)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 24px',
                                  fontFamily: '"DM Sans"',
                                  fontWeight: 600,}}>{t("pages.title.DirectActives")}</TableCell>
                        <TableCell sx={{color: 'rgb(27, 100, 243)',
                                    fontSize: '14px',
                                    margin: '0px',
                                    padding: '15px',
                                    fontFamily: '"DM Sans"',
                                    fontWeight: 600,}}>1</TableCell>
                        <TableCell sx={{color: 'rgb(27, 100, 243)',
                                    fontSize: '14px',
                                    margin: '0px',
                                    padding: '15px',
                                    fontFamily: '"DM Sans"',
                                    fontWeight: 600,}}>3</TableCell>
                        <TableCell sx={{color: 'rgb(27, 100, 243)',
                                    fontSize: '14px',
                                    margin: '0px',
                                    padding: '15px',
                                    fontFamily: '"DM Sans"',
                                    fontWeight: 600,}}>10</TableCell>
                        <TableCell sx={{color: 'rgb(27, 100, 243)',
                                    fontSize: '14px',
                                    margin: '0px',
                                    padding: '15px',
                                    fontFamily: '"DM Sans"',
                                    fontWeight: 600,}}>20</TableCell>
                        <TableCell sx={{color: 'rgb(27, 100, 243)',
                                    fontSize: '14px',
                                    margin: '0px',
                                    padding: '15px',
                                    fontFamily: '"DM Sans"',
                                    fontWeight: 600,}}>30</TableCell>
                        <TableCell sx={{color: 'rgb(27, 100, 243)',
                                    fontSize: '14px',
                                    margin: '0px',
                                    padding: '15px',
                                    fontFamily: '"DM Sans"',
                                    fontWeight: 600,}}>50</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                      <TableRow>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 24px',}}>{t("pages.title.Niveau1")}</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>20%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>20%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>22.5%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>25%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>30%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>30%</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 24px',}}>{t("pages.title.Niveau2")}</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>0%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>20%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>22.5%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>25%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>25%</TableCell>
                        <TableCell sx={{color: 'rgb(118, 118, 118)',
                                  fontSize: '14px',
                                  margin: '0px',
                                  padding: '15px 14px',}}>30%</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div> */}
            {/* <div
          style={{
            marginTop: "30px",
            boxShadow: "0px 10px 60px 0px rgba(226, 236, 249, 0.5)",
            background: "#fff",
            borderRadius: "12px",
            padding: "15px",
          }}
        >
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1.2fr 2.8fr",
              gap: "30px",
              marginBottom: "30px",
              borderBottom: "1px solid rgba(217, 217, 217, 1)",
              paddingBottom: "30px",
            }}
          >
            <div style={{ padding: "15px" }}>
              <h2
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  textAlign: "left",
                  color: "rgba(23, 15, 73, 1)",
                  marginBottom: "25px",
                }}
              >
                {t("pages.title.WhatisCompanyRevenueSharing")}
              </h2>
              <p
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "21px",
                  textAlign: "left",
                  color: "rgba(33, 43, 54, 0.8)",
                }}
              >
                {t("pages.title.Earnapourcentagedetail")}
              </p>
            </div>
            <div style={{ padding: "15px" }}>
              <h2
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  textAlign: "left",
                  color: "rgba(23, 15, 73, 1)",
                  marginBottom: "30px",
                }}
              >
                {t("Qualification Creiteria")}
              </h2>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "30px",
                }}
              >
                <div
                  style={{
                    background: "rgb(221, 221, 221) 71.02%",
                    borderRadius: "16px",
                    padding: "30px 15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="/images/silver-badge.png"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  {dashboardData?.totalNewSale.sale >= 3 &&
                    dashboardData?.totalNewSale.sale < 5 ? (
                    <>
                      <div>
                        <img alt="" src="/images/congratulation-banner.svg" />
                      </div>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "18px",
                          fontWeight: "700",
                          lineHeight: "23.4px",
                          letterSpacing: "-0.01em",
                          textAlign: "center",
                          color: "rgba(38, 180, 24, 1)",
                        }}
                      >
                        You achieved <br />
                        {
                          dashboardData?.qualificationCriteria?.pool[0]
                            ?.pool_name
                        }
                        (1%)
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "24px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          letterSpacing: "-0.01em",
                          textAlign: "center",
                          color: "rgba(38, 180, 24, 1)",
                          padding: "30px 0px",
                        }}
                      >
                        {
                          dashboardData?.qualificationCriteria?.pool[0]
                            ?.pool_name
                        }{" "}
                        (1%)
                      </div>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "18.23px",
                          letterSpacing: "0.34285715222358704px",
                          textAlign: "center",
                          color: "rgba(39, 50, 64, 1)",
                        }}
                      >
                        Make{" "}
                        {
                          dashboardData?.qualificationCriteria?.pool[0]
                            ?.number_of_users
                        }{" "}
                        new sales during the current month
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    background: "rgba(255, 243, 181, 0.92)",
                    boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.07)",
                    borderRadius: "16px",
                    padding: "30px 15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="/images/gold-badge.png"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  {dashboardData?.totalNewSale.sale >= 5 &&
                    dashboardData?.totalNewSale.sale < 10 ? (
                    <>
                      <div>
                        <img alt="" src="/images/congratulation-banner.svg" />
                      </div>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "18px",
                          fontWeight: "700",
                          lineHeight: "23.4px",
                          letterSpacing: "-0.01em",
                          textAlign: "center",
                          color: "rgba(191, 135, 2, 1)",
                        }}
                      >
                        You achieved <br />
                        {
                          dashboardData?.qualificationCriteria?.pool[1]
                            ?.pool_name
                        }
                        (1%)
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "24px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          letterSpacing: "-0.01em",
                          textAlign: "center",
                          color: "rgba(191, 135, 2, 1)",
                          padding: "30px 0px",
                        }}
                      >
                        {
                          dashboardData?.qualificationCriteria?.pool[1]
                            ?.pool_name
                        }{" "}
                        (1%)
                      </div>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "18.23px",
                          letterSpacing: "0.34285715222358704px",
                          textAlign: "center",
                          color: "rgba(39, 50, 64, 1)",
                        }}
                      >
                        Make{" "}
                        {
                          dashboardData?.qualificationCriteria?.pool[1]
                            ?.number_of_users
                        }{" "}
                        new sales during the current month
                      </div>
                    </>
                  )}
                </div>
                <div
                  style={{
                    background: "rgba(222, 231, 246, 1)",
                    boxShadow: "0px 5px 15px 0px rgba(0, 0, 0, 0.07)",
                    borderRadius: "16px",
                    padding: "30px 15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt=""
                      src="/images/platinum-badge.png"
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  {dashboardData?.totalNewSale.sale >= 10 ? (
                    <>
                      <div>
                        <img alt="" src="/images/congratulation-banner.svg" />
                      </div>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "18px",
                          fontWeight: "700",
                          lineHeight: "23.4px",
                          letterSpacing: "-0.01em",
                          textAlign: "center",
                          color: "rgba(12, 59, 134, 1)",
                        }}
                      >
                        You achieved <br />
                        {
                          dashboardData?.qualificationCriteria?.pool[2]
                            ?.pool_name
                        }
                        (1%)
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "24px",
                          fontWeight: "700",
                          lineHeight: "24px",
                          letterSpacing: "-0.01em",
                          textAlign: "center",
                          color: "rgba(12, 59, 134, 1)",
                          padding: "30px 0px",
                        }}
                      >
                        {
                          dashboardData?.qualificationCriteria?.pool[2]
                            ?.pool_name
                        }{" "}
                        (1%)
                      </div>
                      <div
                        style={{
                          fontFamily: "'DM Sans'",
                          fontSize: "14px",
                          fontWeight: "500",
                          lineHeight: "18.23px",
                          letterSpacing: "0.34285715222358704px",
                          textAlign: "center",
                          color: "rgba(39, 50, 64, 1)",
                        }}
                      >
                        Make{" "}
                        {
                          dashboardData?.qualificationCriteria?.pool[2]
                            ?.number_of_users
                        }{" "}
                        new sales during the current month
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              fontFamily: "DM Sans",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "32px",
              textAlign: "left",
              color: "rgba(23, 15, 73, 1)",
            }}
          >
            Make {dashboardData?.qualificationCriteria?.requiredUsers} more
            sales to get promoted to{" "}
            {
              dashboardData?.qualificationCriteria?.pool[
                dashboardData?.qualificationCriteria?.userPool[0]?.id
              ]?.pool_name
            }
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "30px",
            }}
          >
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 3fr",
                  gap: "30px",
                  margin: "40px 0px",
                }}
              >
                <img
                  src="/images/silver-badge.png"
                  alt=""
                  style={{
                    background: "rgba(246, 249, 246, 1)",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "26px",
                      fontWeight: "600",
                      lineHeight: "26px",
                      letterSpacing: "-0.01em",
                      textAlign: "center",
                      color: "rgba(101, 101, 101, 1)",
                      marginBottom: "15px",
                    }}
                  >
                    {dashboardData?.cSymbol}{" "}
                    {dashboardData?.qualificationCriteria?.pool[0]?.amount?.toFixed(
                      2
                    )}
                  </span>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "16px",
                      letterSpacing: "-0.01em",
                      textAlign: "center",
                      color: "rgba(51, 51, 51, 1)",
                    }}
                  >
                    {dashboardData?.qualificationCriteria?.pool[0]?.pool_name}{" "}
                    (1%) <span style={{ opacity: "0.6" }}>will be paid in</span>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  textAlign: "center",
                  color: "rgba(94, 59, 183, 1)",
                }}
              >
                {returnTimer(timeLeftOneMonth)}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 3fr",
                  gap: "30px",
                  margin: "40px 0px",
                }}
              >
                <img
                  src="/images/gold-badge.png"
                  alt=""
                  style={{
                    background: "rgba(246, 249, 246, 1)",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "26px",
                      fontWeight: "600",
                      lineHeight: "26px",
                      letterSpacing: "-0.01em",
                      textAlign: "center",
                      color: "rgba(101, 101, 101, 1)",
                      marginBottom: "15px",
                    }}
                  >
                    {dashboardData?.cSymbol}{" "}
                    {dashboardData?.qualificationCriteria?.pool[1]?.amount?.toFixed(
                      2
                    )}
                  </span>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "16px",
                      letterSpacing: "-0.01em",
                      textAlign: "center",
                      color: "rgba(51, 51, 51, 1)",
                    }}
                  >
                    {dashboardData?.qualificationCriteria?.pool[1]?.pool_name}{" "}
                    (1%) <span style={{ opacity: "0.6" }}>will be paid in</span>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  textAlign: "center",
                  color: "rgba(94, 59, 183, 1)",
                }}
              >
                {returnTimer(timeLeftThreeMonth)}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 3fr",
                  gap: "30px",
                  margin: "40px 0px",
                }}
              >
                <img
                  src="/images/platinum-badge.png"
                  alt=""
                  style={{
                    background: "rgba(246, 249, 246, 1)",
                    padding: "5px",
                    borderRadius: "50%",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "26px",
                      fontWeight: "600",
                      lineHeight: "26px",
                      letterSpacing: "-0.01em",
                      textAlign: "center",
                      color: "rgba(101, 101, 101, 1)",
                      marginBottom: "15px",
                    }}
                  >
                    {dashboardData?.cSymbol}{" "}
                    {dashboardData?.qualificationCriteria?.pool[2]?.amount?.toFixed(
                      2
                    )}
                  </span>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "16px",
                      letterSpacing: "-0.01em",
                      textAlign: "center",
                      color: "rgba(51, 51, 51, 1)",
                    }}
                  >
                    {dashboardData?.qualificationCriteria?.pool[2]?.pool_name}{" "}
                    (1%) <span style={{ opacity: "0.6" }}>will be paid in</span>
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                  textAlign: "center",
                  color: "rgba(94, 59, 183, 1)",
                }}
              >
                {returnTimer(timeLeftSixMonth)}
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                fontFamily: "DM Sans",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "32px",
                textAlign: "left",
                color: "rgba(23, 15, 73, 1)",
                marginBottom: "30px",
                marginTop: "30px",
              }}
            >
              My Total New Sales
            </div>
            <div
              style={{
                display: "flex",
                gap: "15px",
                width: "100%",
                overflow: "hidden",
              }}
            >
              {dashboardData?.referralNewUserData?.referralUserData?.map((userData, index) => (
                <div style={{ width: "82px" }}>
                  <img
                    src="https://gravatar.com/avatar/HASH"
                    // src={dashboardData?.referralNewUserData?.pictureUrl + userData?.picture}
                    alt=""
                    style={{
                      height: "40px",
                      width: "40px",
                      margin: "auto",
                      display: "block",
                      marginBottom: "10px",
                    }}
                  />
                  <div
                    style={{
                      fontFamily: "DM Sans",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "16.63px",
                      textAlign: "center",
                      color: "rgba(21, 29, 72, 1)",
                    }}
                  >
                    {userData?.firstname + " " + userData?.lastname}
                  </div>
                </div>
              )
              )}
            </div>
          </div>
        </div> */}
            {/* <div className="data-table-area" style={{
          backgroundColor: '#fff',
          boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          margin: '20px 0',
          padding: '20px',
          }}>
          <div
            style={{
              marginBottom: "20px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              background: "#E9F1FF",
              padding: '20px',
              borderRadius: '12px',
            }}
          >
            <div style={{
              color: "rgba(27, 100, 243, 1)",
              display: "flex",
              alignItems:"center",

            }}>
              <h3
                style={{
                  fontFamily: "'DM Sans'",
                  fontSize: "24px",
                  fontWeight: "700",
                  lineHeight: "32px",
                  textAlign: "left",
                  color: "rgba(21, 29, 72, 1)",
                  marginRight: "4px"
                }}
              >
                New Trials
              </h3>
              <span
                style={{
                  fontFamily: "'DM Sans'",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "32px",
                  textAlign: "left",
                  color: "rgba(21, 29, 72, 1)",
                  marginTop:"2px"
                }}
              >
                (last 30 days)
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <Link
                to="/affiliate/all-customers"
                style={{
                  background: "rgba(44, 115, 255, 1)",
                  padding: "10px",
                  color: "rgb(255, 255, 255)",
                  fontFamily: "'DM Sans'",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "21.6px",
                  textAlign: "left",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <RxDashboard height={20} width={20} />{" "}
                <span>View All Trials</span>
              </Link>
             
            </div>
          </div>
            <CustomerTable refUsers={refUsers} showStatus={false} />
        </div> */}

            <div className="data-table-area" style={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              borderRadius: '12px',
              margin: '30px 0px 15px',
              padding: '0px',
            }}>
              <div style={{
                display: 'flex',
                justifyContent: "space-between",
                alignItems: "center"

              }}>
                <div style={{
                  display: 'flex',
                  gap: "0 10px",
                  alignItems: "center"
                }}>
                  <button
                    onClick={() => setCurrentTab('trial')}
                    className="tabmenu-btn"
                    style={{
                      ...commonButtonStyle,
                      ...(currentTab === 'trial' ? activeStyle : {}),
                      ':hover': hoverStyle,
                    }}
                  >
                    {t("pages.title.new-trials")}
                  </button>
                  <button
                    onClick={() => setCurrentTab('paid')}
                    className="tabmenu-btn"
                    style={{
                      ...commonButtonStyle,
                      ...(currentTab === 'paid' ? activeStyle : {}),
                      ':hover': hoverStyle,
                    }}
                  >
                    {t("pages.title.NewCustomers")}
                  </button>
                </div>

              </div>
              <div className="oct-affiliate-strip"
                style={{
                  display: "grid",
                  gridTemplateColumns: "4fr 1fr",
                  backgroundColor: "rgb(255, 255, 255)",
                  padding: '0px 24px',
                  borderRadius: '0px 12px 0px 0px',
                }}
              >
                <div style={{
                  color: "rgba(27, 100, 243, 1)",
                  display: "flex",
                  alignItems: "center",

                }}>
                  <h3
                    style={{
                      fontFamily: "'DM Sans'",
                      fontSize: "21px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      textAlign: "left",
                      color: "rgba(21, 29, 72, 1)",
                      marginRight: "8px"
                    }}
                  >
                    {currentTab === 'trial'
                      ? t("pages.title.new-trials")
                      : t("pages.title.referred-customers")
                    }
                  </h3>
                  <span
                    style={{
                      fontFamily: "'DM Sans'",
                      fontSize: "18px",
                      fontWeight: "500",
                      lineHeight: "32px",
                      textAlign: "left",
                      color: "rgba(21, 29, 72, 1)",
                      marginTop: "2px"
                    }}
                  >
                    {t("pages.title.last-30-days")}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <Link
                    to={`/affiliate/all-customers?tab=${currentTab}`}
                    style={{
                      background: 'rgb(44, 115, 255)',
                      padding: '12px 16px',
                      color: 'rgb(255, 255, 255)',
                      fontFamily: '"DM Sans"',
                      fontSize: '15px',
                      fontWeight: 500,
                      lineHeight: 1,
                      textAlign: 'left',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '6px',
                    }}
                  >
                    <RxDashboard height={20} width={20} />{" "}
                    <span>{t("pages.title.view-all")}</span>
                  </Link>

                </div>
              </div>
              {
                currentTab === 'trial'
                  ? <CustomerTable refUsers={refUsers} showStatus={false} />
                  : <RefCustomerTable refUsers={refUsers} showStatus={false} />
              }
            </div>

            <div className="data-table-area" style={{
              backgroundColor: 'transparent',
              boxShadow: 'none',
              borderRadius: '12px',
              margin: '30px 0px 20px',
              padding: '0px',
              boxShadow: '0 5px 20px rgb(0 0 0 / 10%)',
            }}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                borderRadius: '12px',
                backgroundColor: 'rgb(255, 255, 255)'
              }}>

                <h3
                  style={{
                    fontFamily: "'DM Sans'",
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "rgba(21, 29, 72, 1)",
                    margin: "0px"
                  }}
                >
                  {t("pages.title.my-payments")}
                </h3>
                <PaymentTable payoutreportdata={payoutReportData} />
              </div>
            </div>
          </div>
      }
      {/* <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
        <TinyBarChart/>
        </Grid>
        <Grid item xs={12} md={6}>

        </Grid>
      </Grid> */}
    </AffiliateLayout>
  );
}
