import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { CssBaseline, Dialog, IconButton } from "@mui/material";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import JumboLayoutFooter from "./JumboLayoutFooter";
import JumboLayoutSidebar from "./JumboLayoutSidebar";
import Div from "@jumbo/shared/Div";
import useJumboLayoutRoot from "@jumbo/hooks/useJumboLayoutRoot";
import useJumboLayoutContent from "@jumbo/hooks/useJumboLayoutContent";
import { useContext } from "react";
import { AppContext } from "app/AppContext"
import SidebarSkeleton from "app/layouts/shared/sidebars/Sidebar/SidebarSkeleton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NewUpgrade from "app/pages/upgradepriceplan/NewUpgardePlan";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import UpgardeOfferPOP from "app/layouts/shared/offersPop/upgardeOfferPOP";

const JumboLayout = (props) => {
  const { sidebarOptions, setSidebarOptions, setSideBarOpen, sideBarOpen } =
    useJumboLayoutSidebar();
  const { rootOptions } = useJumboLayoutRoot();
  const { contentOptions } = useJumboLayoutContent();
  const { checkLogin } = useContext(AppContext);
  const { upgradePop, setUpgradePop,loginUserData } = useContext(CustomProvider);
  const [isPop,setIsPop] = useState(false)
  const LoginStatus = checkLogin();

  useEffect(()=>{
    if(loginUserData?.sub_type){
      const  plan = loginUserData?.planId?.split('-')
      if(loginUserData?.sub_type !== "year" && `${plan?.[0]}-${plan?.[1]}` !== "Special-Offer" ){
        setIsPop(true)
      }else{
        setIsPop(false)
      }
    }
   
    
  },[loginUserData])

  const headerHeightProps = React.useMemo(() => {
    if (props?.headerSx?.height) {
      return { height: props?.headerSx?.height };
    }
    return {};
  }, [props?.headerSx]);

  return (
    <Div
      sx={{
        display: "flex",
        flex: 1,
        minWidth: 0,
        minHeight: "100%",
        flexDirection: "column",
        ...rootOptions?.sx,
      }}
      className="CmtLayout-root"
    >
      <CssBaseline />

      <Suspense
        fallback={
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              alignItems: "center",
              alignContent: "center",
              px: 3,
            }}
          >
            <SidebarSkeleton />
          </Div>
        }
      ></Suspense>

      <Div
        sx={{
          display: "flex",
          flex: 1,
          minWidth: 0,
          position: "relative",
        }}
        className="CmtLayout-wrapper"
      >
        {LoginStatus && (
          <JumboLayoutSidebar headerHeightProps={headerHeightProps}>
            {props.sidebar}
          </JumboLayoutSidebar>
        )}

        <Dialog
        open={upgradePop}
        onClose={()=>setUpgradePop(false)}
        fullWidth
        sx={{
          ".MuiPaper-root ": {
            maxWidth: "100%",
            height:"90vh",
            overflow: "scroll"
          },
        }}
      >
      <div className="NewUpgrade_wrap" style={{overflow:"auto",width:"100%"}}>
      <NewUpgrade/>
      </div>
      </Dialog>

      {isPop && <UpgardeOfferPOP/>}

        <Div
          sx={{
            display: "flex",
            minWidth: 0,
            flex: 1,
            flexDirection: "column",
            minHeight: "100%",
            transition: (theme) => theme.transitions.create(["margin-left"]),
          }}
          className="CmtLayout-main"
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              py: 3,
              px: { lg: 6, xs: 4 },
              ...(contentOptions?.sx ?? {}),
            }}
            className="CmtLayout-content"
          >
            {!sideBarOpen && LoginStatus && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                style={{
                  border: "1px solid #D8D8D8",
                  position: "absolute",
                  left: "60px",
                  zIndex: 9999,
                  background: "rgb(255, 255, 255)",
                }}
                onClick={() => {
                  setSidebarOptions({
                    open: !sidebarOptions?.open,
                  });
                  setSideBarOpen(!sideBarOpen);
                }}
              >
                <ArrowForwardIosIcon style={{ fontSize: "1rem" }} />
              </IconButton>
            )}

            {props.children}
          </Div>
          <JumboLayoutFooter>{props.footer}</JumboLayoutFooter>
        </Div>
      </Div>
    </Div>
  );
};

JumboLayout.propTypes = {
  header: PropTypes.node,
  headerSx: PropTypes.object,
  sidebar: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node,
};

export default JumboLayout;