import { Dialog } from '@mui/material';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import React, { useContext, useEffect, useState } from 'react'
// import Eng_3M from "../../../../assets/img/3_M_eng.png"
// import Fr_3M from "../../../../assets/img/3_M_fr.png"
// import De_3M from "../../../../assets/img/3_M_de.png"
// import Eng_1Y from "../../../../assets/img/1_Y_eng.png"
// import Fr_1Y from "../../../../assets/img/1_Y_fr.png"
// import De_1Y from "../../../../assets/img/1_Y_de.png"
import bl_eng from "../../../../assets/img/bl_EN.png"
import bl_de from "../../../../assets/img/bl_DE.png"
import bl_fr from "../../../../assets/img/bl_FR.png"
import "./upgradePop.css"
import { t } from 'i18next';


const UpgardeOfferPOP = () => {
    const [showPopup, setShowPopup] = useState(false);
  const { loginUserData , setUpgradePop} = useContext(CustomProvider);
  const  [selectedImg , setSelectedImg] = useState(bl_eng)
  const currentLang = localStorage.getItem('selectedLocale')




  // Check if the user already accepted the reminder
  useEffect(() => {
    // if (currentLang === "en-US") {
    //   if (loginUserData?.sub_type === "month" &&  loginUserData?.plan_period === 1) {
    //     setSelectedImg(Eng_3M)
    //   }else if(loginUserData?.sub_type === "month" &&  loginUserData?.plan_period === 3){
    //     setSelectedImg(Eng_1Y)
    //   }
    // }else if (currentLang === "fr-FR"){
    //   if (loginUserData?.sub_type === "month" &&  loginUserData?.plan_period === 1) {
    //     setSelectedImg(Fr_3M)
    //   }else if(loginUserData?.sub_type === "month" &&  loginUserData?.plan_period === 3){
    //     setSelectedImg(Fr_1Y)
    //   }
    // }else if (currentLang === "de-DE"){
    //   if (loginUserData?.sub_type === "month" &&  loginUserData?.plan_period === 1) {
    //     setSelectedImg(De_3M)
    //   }else if(loginUserData?.sub_type === "month" &&  loginUserData?.plan_period === 3){
    //     setSelectedImg(De_1Y)
    //   }
    // }

    if (currentLang === "en-US") {
      setSelectedImg(bl_eng)
    }else if (currentLang === "fr-FR"){
      setSelectedImg(bl_fr)
    }else if (currentLang === "de-DE"){
      setSelectedImg(bl_de)
    
    }
    const hasAccepted = localStorage.getItem('hasAccepted');
    if (!hasAccepted) {
      triggerPopup(true);
    }
  }, []);


  // Function to trigger the popup every 30 minutes if not accepted
  const triggerPopup = (first) => {
    if (first) {
      setShowPopup(true);
    } else {
    
    setTimeout(() => {
      setShowPopup(true);
    },30 * 60 * 1000); // 30 minutes
  }
  };

  // Handle the "Accept" action
  const handleAccept = () => {
    localStorage.setItem('hasAccepted', 'true'); // Save acceptance status

    setShowPopup(false);
    setUpgradePop(true)
  };

  // Handle the "Remind Me Later" action
  const handleRemindLater = () => {
    setShowPopup(false);
    triggerPopup(); // Trigger the popup again in 30 minutes
  };

  
  return (
    <>
  <Dialog
        open={showPopup }
        fullWidth
        sx={{
          ".MuiPaper-root ": {
            maxWidth: "100%",
            height:"100vh",
            overflow: "scroll",
            "justifyContent": "center"
          },
        }}
      >
      <div class="halloween-popup-inner" >
			<div class="halloween-popup-content halloween-scroll">
				
				<div class="halloween-popup-text">
					<img src={selectedImg}/>
					<div class="halloween-btn-wraper">
						<span class="halloween-btns" style={{background:"lightgray",color:"gray"}} onClick={()=> handleRemindLater()}>{t("pages.title.Remind me later")}</span>
						<span class="halloween-btns" style={{background:"green"}} onClick={()=>handleAccept()}>{t("sidebar.menuItem.upgrade-price-plan")}</span>
					</div>
				</div>
			</div>
		</div>
     
      </Dialog>
    </>
  )
}

export default UpgardeOfferPOP