import { Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react'
import "./upgradePop.css"
import { t } from 'i18next';
import { useNavigate } from 'react-router';
import Ge_offers from "../../../../assets/img/Prospects DE.png"
import En_offers from "../../../../assets/img/Prospects ENG.png"
import Fr_offers from "../../../../assets/img/Prospects FR.png"


const PlanOfferPOP = () => {
    const [showPopup, setShowPopup] = useState(false);
  const  [selectedImg , setSelectedImg] = useState()
  const currentLang = localStorage.getItem('selectedLocale')
  const navigate =useNavigate()




  // Check if the user already accepted the reminder
  useEffect(() => {
    if (currentLang === "en-US") {
      setSelectedImg(En_offers)
    }else if (currentLang === "fr-FR"){
      setSelectedImg(Fr_offers)
    }else if (currentLang === "de-DE"){
      setSelectedImg(Ge_offers)
    }
    setTimeout(() => {
      setShowPopup(true)
        
      }, 3000);
   
  }, []);

  const handleAccept =() =>{
    navigate("/offers")
  }

  
  return (
    <>
  <Dialog
        open={showPopup }
        fullWidth
        sx={{
          ".MuiPaper-root ": {
            maxWidth: "100%",
            height:"100vh",
            overflow: "scroll",
            "justifyContent": "center"
          },
        }}
      >
      <div class="halloween-popup-inner" >
			<div class="halloween-popup-content halloween-scroll">
				<div class="halloween-popup-text">
					<img src={selectedImg}/>
					<div class="halloween-btn-wraper">
						<span class="halloween-btns" style={{background:"lightgray",color:"gray"}} onClick={()=> setShowPopup(false)}>{t("pages.title.cancel")}</span>
						<span class="halloween-btns" style={{background:"green"}} onClick={()=>handleAccept()}>{t("pages.title.Get_offers")}</span>
					</div>
				</div>
			</div>
		</div>
     
      </Dialog>
    </>
  )
}

export default PlanOfferPOP